<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader
        class="mx-auto"
        type="table"
      ></v-skeleton-loader>
    </div>
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" style="width: 33%">
              Van
            </th>
            <th class="text-left" style="width: 33%">
              Timings
            </th>
            <th class="text-left" style="width: 33%">
              
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in vans"
            :key="item.id"
          >
            <td v-if="item.vanChecksOut">
              <div class="d-flex flex-row align-center">
                <v-icon x-large class="mr-5">mdi-van-passenger</v-icon>
                <span>{{ item.brandModel }}</span>
              </div>
            </td>
            <td v-if="item.vanChecksOut">{{ item.timings ? item.timings : 'N/A' }}</td>
            <td v-if="item.vanChecksOut"><v-btn color="primary" @click="selectVan(item)">Validate</v-btn></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>

import * as dayjs from 'dayjs'
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export default {

  props: {
    date: {
      type: String,
      required: true
    },
    confirmDriver: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      vans: [],
      selectedVan: {},
      vanChecksOut: false,
      selectedDriver: null,
      loading: true
    }
  },

  created() {
    this.$store.dispatch("order/getVans").then(async (vans) => {
      this.vans = vans;
      for(let i=0; i<this.vans.length; i++) {
        let timeData = await this.getWeekDayTiming(this.vans[i])
        this.vans[i].vanChecksOut = timeData.condition
        this.vans[i].timings = timeData.data
      }
      console.log(this.vans)
      this.loading = false
    })
  },

  methods: {

    async selectVan(van) {
      try {
        const tripWeekDay = Number(dayjs(this.date).format('d'))
        let driverId = van.macroPlanning[tripWeekDay].driverId
        let driver = await this.$store.dispatch('order/getDriverById', { id: driverId })
        this.confirmDriver(van, driver)
        return true
      } catch (error) {
        console.log(error)
      }
    },

    async getWeekDayTiming(van, index) {

      let item = van.macroPlanning

      const tripWeekDay = Number(dayjs(this.date).format('d'))
      // Getting time of trip
      const triptime = dayjs(this.date).format('HH:mm')

      let vanTime = item[tripWeekDay].time

      if(!vanTime) {
        return Promise.resolve({ condition: false, data: 'N/A' })
      }

      // Checking if the time falls between the schedule of driver and van
      if(dayjs(`2022-04-20 ${triptime}`).isBetween(dayjs(`2022-04-20 ${vanTime.split('-')[0]}`).subtract(1, 'minute'), dayjs(`2022-04-20 ${vanTime.split('-')[1]}`), 'second')) {

        // console.log(vanTime, 'Yay')

        let conflictResult = await this.checkTripConflict(van, this.date)

        console.log(conflictResult)

        if(conflictResult) return Promise.resolve({ condition: false, data: `${vanTime} (Conflict with another trip)` })

        else return Promise.resolve({ condition: true, data: vanTime })
      } else {

        // console.log(vanTime, 'Nay')

        return Promise.resolve({ condition: false, data: vanTime })

      }
      
    },

    async checkTripConflict(van, date) {
      try {

        let result = false

        let resultArray = []

        // console.log(date)

        let tripsArray = await this.$store.dispatch("order/getOrderByStatusDateVanId", { date: date.split(' ')[0], status: 'Scheduled', vanId: van.id })

        console.log(tripsArray)

        tripsArray.forEach(item => {
          let orderTime = this.getCompleteTripTime(item)

          let unixDateTime = Number(dayjs(date).format('X'))

          console.log(orderTime, unixDateTime)

          if(unixDateTime > Number(dayjs(orderTime.split(' - ')[0]).format('X')) && unixDateTime < Number(dayjs(orderTime.split(' - ')[1]).format('X'))) resultArray.push(true)
        })

        if(resultArray.length > 0) return Promise.resolve(true)
        else return Promise.resolve(false)
        
      } catch (error) {
        console.log(error)
      }
    },

    getCompleteTripTime(tmpObject) {
      // Main trip
      let mainTripStartTime = dayjs.unix(tmpObject.pickUpDateTime).format('YYYY-MM-DD HH:mm')
      let mainTripEndTime = dayjs(mainTripStartTime).add(Number(tmpObject.estimatedTime.split(' ')[0]), 'm').format('YYYY-MM-DD HH:mm')

      // Garage to source time and rest
      let tpcDbStart = dayjs(mainTripStartTime).subtract(tmpObject.garageToSource.time + tmpObject.garageToSource.rest, 'm').format('YYYY-MM-DD HH:mm')
      let tpcDbEnd = dayjs(tpcDbStart).add(tmpObject.garageToSource.time, 'm').format('YYYY-MM-DD HH:mm')

      // After source rest
      let trPreVStart = tpcDbEnd
      let trPreVEnd = mainTripStartTime

      // Post trip Rest
      let trPostVStart = mainTripEndTime
      let trPostVEnd = dayjs(mainTripEndTime).add(tmpObject.destinationToGarage.rest, 'm').format('YYYY-MM-DD HH:mm')

      // Destination to garage
      let treVbStart = trPostVEnd
      let treVbEnd = dayjs(trPostVEnd).add(tmpObject.destinationToGarage.time, 'm').format('YYYY-MM-DD HH:mm')

      return `${tpcDbStart} - ${trPostVEnd}`
    },
  },

}
</script>

<style>

</style>