var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"home",staticStyle:{"height":"100vh"},attrs:{"color":"primary"}},[_c('div',{staticClass:"login d-flex align-center justify-center",staticStyle:{"height":"100%"}},[_c('v-card',{staticClass:"pa-5 mx-3",attrs:{"max-width":"960"}},[_c('div',{staticClass:"d-flex align-center flex-column"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"src":require("@/assets/logo.svg"),"min-height":"75","max-width":"200","contain":""}})],1),_c('v-card-title',{staticClass:"headline",staticStyle:{"word-break":"normal","text-align":"center"}},[_vm._v("Register")])],1),_c('v-divider'),_c('v-subheader',{staticClass:"error--text caption"},[_vm._v(_vm._s(_vm.errorMsg))]),_c('div',{},[_c('v-row',{staticClass:"mb-5",attrs:{"no-gutters":""}},[_vm._l((_vm.formText),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","md":"6"}},[(item.id === 5)?_c('v-text-field',{staticClass:"px-4",attrs:{"label":item.title,"type":"text","outlined":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_c('v-text-field',{staticClass:"px-4",attrs:{"label":item.title,"type":"text","outlined":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"label":"Date of Birth","append-icon":"mdi-calendar","outlined":"","readonly":""},on:{"blur":function($event){_vm.dobDate = _vm.parseDate(_vm.dob)}},model:{value:(_vm.dob),callback:function ($$v) {_vm.dob=$$v},expression:"dob"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"color":"primary","active-picker":_vm.activePicker,"max":new Date(
                    Date.now() - new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .substr(0, 10),"min":"1950-01-01","locale":"fr"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save},model:{value:(_vm.dobDate),callback:function ($$v) {_vm.dobDate=$$v},expression:"dobDate"}})],1)],1)],2)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","large":"","elevation":"0","block":"","loading":_vm.loader},on:{"click":function($event){$event.stopPropagation();return _vm.register.apply(null, arguments)}}},[_vm._v("Register")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }