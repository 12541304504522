<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2 class="text-center my-10">Date & Hour: {{ dateAndTime }}</h2>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          readonly
          label="Lieu de départ"
          v-model="payload.trip.pickUp.name"
          append-icon="mdi-map-marker"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          readonly
          label="Lieu d'arrivée"
          v-model="payload.trip.dropOff.name"
          append-icon="mdi-map-marker"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          readonly
          label="Distance"
          v-model="payload.trip.distance"
          append-icon="mdi-arrow-expand-horizontal"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          readonly
          label="Durée"
          v-model="payload.trip.time"
          append-icon="mdi-timer-sand-empty"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          readonly
          label="Marque voiture"
          v-model="payload.van.brand"
          append-icon="mdi-van-passenger"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          readonly
          label="Modèle voiture"
          v-model="payload.van.model"
          append-icon="mdi-square-circle"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          dense
          outlined
          readonly
          label="Prix trajet"
          append-icon="mdi-cash-100"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="12" md="4">
        <v-card outlined>
          <v-card-title class="ml-5">Details</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item two-line v-for="(item, i) in customerDetails" :key="i">
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.value }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-subheader>Trip Details</v-subheader>
            <v-list>
              <v-list-item two-line v-for="(item, i) in tripDetails" :key="i">
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.value }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card outlined class="py-5">
          <v-card-title class="display-3 font-weight-medium">Réserver et payer hors-plateforme</v-card-title>
          <v-card-text class="display-1 mt-10">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eum eos vel eaque velit doloribus sed dolorum reiciendis omnis consectetur optio qui doloremque, voluptatum voluptatem dolor ab, officia aut voluptatibus quasi!</v-card-text>
          <v-card-actions class="d-flex justify-center mt-10">
            <v-btn color="primary" class="px-10" large>Réserver</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card outlined class="py-5">
          <v-card-title class="display-3 font-weight-medium">Réserver et payer en ligne</v-card-title>
          <v-card-text class="display-1 mt-10">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eum eos vel eaque velit doloribus sed dolorum reiciendis omnis consectetur optio qui doloremque, voluptatum voluptatem dolor ab, officia aut voluptatibus quasi!</v-card-text>
          <v-card-actions class="d-flex justify-center mt-10">
            <v-btn color="primary" class="px-10" large>Réserver et payer</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import dayjs from 'dayjs'

export default {

  props: {
    payload: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      customerDetails: [],
      tripDetails: [],
      dateAndTime: '',
    }
  },

  computed: {
    ...mapState({
      customer: state => state.loggedInCustomer
    })
  },

  created() {
    console.log(this.payload)
    let dateString = this.payload.dateAndTime.split(' ')[0]
    let timeString = this.payload.dateAndTime.split(' ')[1]
    this.dateAndTime = `${dateString.split('-')[2]}/${dateString.split('-')[1]}/${dateString.split('-')[0]} ${timeString.replace(':', 'h')}`
    this.customerDetails = [
      {
        title: 'ID Client',
        value: this.customer.customId
      },
      {
        title: 'Prénom et nom client',
        value: `${this.customer.firstName} ${this.customer.lastName}`
      },
      {
        title: 'Date de naissance',
        value: this.customer.dob
      },
      {
        title: 'Adresse',
        value: this.customer.address
      },
      {
        title: 'Numéro de téléphone',
        value: this.customer.phoneNumber
      }
    ]
    this.tripDetails = [
      {
        title: 'Nombre de voyageurs',
        value: this.payload.details.passengers
      },
      {
        title: 'Nombre de bagages 23kg',
        value: this.payload.details.luggages
      },
      {
        title: 'Nombre de sièges bébé',
        value: this.payload.details.babySeats
      },
    ]
  },

}
</script>

<style>
</style>