<template>
  <div>
    <order-map :getMapData="getMapData" :key="shouldReRender" />

    <div class="d-flex justify-space-between mt-5" v-if="trip.time">
    <v-btn color="primary" class="mt-10" @click="goNext">Continue</v-btn>
    <v-btn color="primary" class="mt-10" @click="changeAddress">Change Address</v-btn>
    </div>
  </div>
</template>

<script>
import OrderMap from '../shared/OrderMap.vue'
export default {
  components: { OrderMap },

  props: {
    confirmTrip: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      shouldReRender: false,
      trip: {
        time: '',
        distance: '',
        pickUp: {},
        dropOff: {}
      }
    }
  },


  methods: {

    goNext() {
      this.confirmTrip(this.trip)
    },

    changeAddress() {
      this.shouldReRender = !this.shouldReRender
      this.trip = {
        time: '',
        distance: '',
        pickUp: {},
        dropOff: {}
      }
    },

    getMapData(payload) {
      this.trip.time = payload.tripTime
      this.trip.distance = payload.tripDistance
      this.trip.pickUp = payload.pickUp
      this.trip.dropOff = payload.dropOff
    },
  }

}
</script>

<style>

</style>