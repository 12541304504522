<template>
  <div>
    <v-row class="mt-5">
      <v-col cols="12" md="6"
        ><v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              dense
              append-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              @blur="dateTmp = parseDate(date)"
              v-on="on"
              label="Date"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            v-model="dateTmp"
            @input="dateMenu = false"
            :min='minDate'
            locale="fr"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          dense
          outlined
          v-mask="['##:##']"
          label="Time"
          placeholder="12:00"
          v-model="time"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-btn color="primary" class="mt-10" @click="goNext">Continue</v-btn>
  </div>
</template>

<script>

import { mask } from "vue-the-mask"
import dayjs from 'dayjs'

export default {

  directives: { mask },

  props: {
    confirmDateTime: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      date: null,
      dateTmp: null,
      dateMenu: false,
      time: null,
      minDate: dayjs().format('YYYY-MM-DD')
    }
  },

  watch: {
    dateTmp(val) {
      this.date = this.formatDate(this.dateTmp);
    },
  },

  methods: {

    goNext() {
      let dateTime = `${this.formatDate(this.date)} ${this.time}`;
      this.confirmDateTime(dateTime)
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  }

}
</script>

<style>

</style>