<template>
  <div>
    <v-row class="mt-5">
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          dense
          label="Name"
          readonly
          v-model="details.customerName"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          dense
          label="Phone"
          readonly
          v-model="details.customerPhone"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          dense
          label="Email"
          readonly
          v-model="details.customerEmail"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          dense
          label="Passengers"
          type="number"
          v-model="details.passengers"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          dense
          label="Luggages"
          type="number"
          v-model="details.luggages"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          outlined
          dense
          :items="[0, 1, 2]"
          :label="'Baby Seats'"
          v-model="details.babySeats"
        ></v-select>
      </v-col>
    </v-row>
    <v-btn color="primary" class="mt-10" @click="goNext">Continue</v-btn>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {

  props: {
    confirmDetails: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      details: {
        customerId: '',
        customerName: '',
        customerPhone: '',
        customerEmail: '',
        passengers: '',
        luggages: '',
        babySeats: 0,
      }
    }
  },

  created() {
    this.details.customerName = `${this.customer.firstName} ${this.customer.lastName}`
    this.details.customerPhone = this.customer.phoneNumber
    this.details.customerEmail = this.customer.email
    this.details.customerId = this.customer.id

  },

  computed: {
    ...mapState({
      customer: (state) => state.loggedInCustomer
    })
  },

  methods: {
    goNext() {
      this.confirmDetails(this.details)
    }
  }

}
</script>

<style>

</style>