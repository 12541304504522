<template>
  <v-sheet color="primary" class="home" style="height: 100vh">
    <div class="login d-flex align-center justify-center" style="height: 100%">
      <v-card max-width="960" class="pa-5 mx-3">
        <!-- Card Header -->
        <div class="d-flex align-center flex-column">
          <router-link to="/">
            <v-img
              src="~@/assets/logo.svg"
              min-height="75"
              max-width="200"
              contain
            ></v-img>
          </router-link>
          <v-card-title
            style="word-break: normal; text-align: center"
            class="headline"
            >Register</v-card-title
          >
        </div>
        <v-divider></v-divider>
        <v-subheader class="error--text caption">{{ errorMsg }}</v-subheader>
        <!-- End Card Header -->
        <!-- Login Fields -->
        <div class="">
          <v-row class="mb-5" no-gutters>
            <v-col cols="12" md="6" v-for="item in formText" :key="item.id">
              <v-text-field
                v-if="item.id === 5"
                :label="item.title"
                v-model="item.value"
                type="text"
                class="px-4"
                outlined
              ></v-text-field>
              <v-text-field
                v-else
                :label="item.title"
                v-model="item.value"
                type="text"
                class="px-4"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mx-4"
                    v-model="dob"
                    label="Date of Birth"
                    append-icon="mdi-calendar"
                    outlined
                    readonly
                    v-bind="attrs"
                    @blur="dobDate = parseDate(dob)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="dobDate"
                  :active-picker.sync="activePicker"
                  :max="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1950-01-01"
                  @change="save"
                  locale="fr"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-btn
            color="primary"
            large
            elevation="0"
            block
            :loading="loader"
            @click.stop="register"
            >Register</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>
import * as fb from "../firebase";

export default {
  name: "Login",

  data: () => ({
    credentials: {
      email: "testClient@gmail.com",
      password: "1234567890",
    },

    errorMsg: "",
    loader: false,
    dobDate: "",
    dob: "",
    dateMenu: false,
    activePicker: null,
    formText: [
      {
        id: 0,
        title: "First Name",
        value: "1",
      },
      {
        id: 1,
        title: "Last Name",
        value: "2",
      },
      {
        id: 2,
        title: "Email",
        value: "testing123@gmail.com",
      },
      {
        id: 3,
        title: "Password",
        value: "1234567890",
      },
      {
        id: 4,
        title: "Address",
        value: "12334",
      },
      {
        id: 5,
        title: "Phone Number",
        value: "123444",
      },
    ],
  }),

  watch: {
    dobDate(val) {
      this.dob = this.formatDate(this.dobDate);
    },
    dateMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  methods: {
    async register() {
      try {
        this.loader = true;

        const newUser = await fb.auth.createUserWithEmailAndPassword(
          this.formText[2].value,
          this.formText[3].value
        );

        console.log(newUser.user.uid);

        const payload = {
          customId: `SN-CP-${Math.floor(Math.random() * 1000000)}`,
          firstName: this.formText[0].value,
          lastName: this.formText[1].value,
          email: this.formText[2].value,
          address: this.formText[4].value,
          phoneNumber: this.formText[5].value,
          dob: this.dob,
          uid: newUser.user.uid,
        };

        console.log(payload)

        const newCustomer = await fb.db.collection('customer').add(payload)

        console.log(newCustomer)

        this.loader = false;
      } catch (error) {
        console.log(error);
        this.loader = false;
      }
    },

    save(date) {
      this.$refs.dateMenu.save(date);
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
<style></style>
