<template>
  <v-sheet class="pa-5" style="min-height: 100vh">
    <h2 class="my-5">Start a new trip</h2>
    <v-stepper v-model="el">
      <v-stepper-header>
        <v-stepper-step :complete="el > 1" step="1">Date & Time</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="el > 2" step="2">Driver</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="el > 3" step="3">Details</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="el > 4" step="4">Trip</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="el > 5" step="5">Payment</v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-card-text>
              <order-date-time :confirmDateTime="confirmDateTime" />
            </v-card-text>
            <v-card-actions>
              <!-- <v-btn color="primary" elevation="0" @click="el = 2">Continue</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card>
            <v-card-text v-if="dateAndTime">
              <order-driver :date="dateAndTime" :confirmDriver="confrimDriver" />
            </v-card-text>
            <v-card-actions>
              <!-- <v-btn color="primary" elevation="0" @click="el = 3">Continue</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card>
            <v-card-text v-if="el == 3">
              <order-details :confirmDetails="confirmDetails" />
            </v-card-text>
            <v-card-actions>
              <!-- <v-btn color="primary" elevation="0" @click="el = 4">Continue</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-card>
            <v-card-text>
              <order-trip :confirmTrip="confirmTrip" />
            </v-card-text>
            <v-card-actions>
              <!-- <v-btn color="primary" elevation="0" @click="el = 5">Continue</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="5">
          <v-card>
            <v-card-text>
              <order-payment v-if="el == 5" :payload="finalPayload" />
            </v-card-text>
            <!-- <v-divider></v-divider> -->
            <v-card-actions>
              <!-- <v-btn color="primary" elevation="0" @click="el = 1">Continue</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    {{ dateAndTime }}
    {{ selectedDriver.fullName }}
    {{ selectedVan.brandModel }}
    {{ details.passengers }}
    {{ trip }}
  </v-sheet>
</template>

<script>
import OrderDateTime from '../../components/user/OrderDateTime.vue'
import OrderDetails from '../../components/user/OrderDetails.vue'
import OrderDriver from '../../components/user/OrderDriver.vue'
import OrderPayment from '../../components/user/OrderPayment.vue'
import OrderTrip from '../../components/user/OrderTrip.vue'
export default {
  components: { OrderDateTime, OrderDriver, OrderDetails, OrderPayment, OrderTrip },

  data() {
    return {
      el: 1,
      dateAndTime: '',
      selectedVan: {},
      selectedDriver: {},
      details: {},
      trip: {},
      finalPayload: {}
    }
  },

  methods: {
    confirmDateTime(dateTime) {
      this.dateAndTime = dateTime
      this.el = 2
    },

    confrimDriver(van, driver) {
      this.selectedVan = van
      this.selectedDriver = driver
      this.el = 3
    },

    confirmDetails(payload) {
      this.details = payload
      this.el = 4
    },

    confirmTrip(payload) {
      this.trip = payload
      this.finalPayload = {
        dateAndTime: this.dateAndTime,
        van: this.selectedVan,
        details: this.details,
        trip: this.trip
      }
      this.el = 5
    }
  }

}
</script>

<style>

</style>